import React from 'react';
import PropTypes from 'prop-types';
import './eventModal.scss'; // Add custom styles for the modal
import parse from 'html-react-parser';

export const EventModal = ({ event, onClose }) => {
  if (!event) return null;

  return (
    <div className="event-modal-overlay">
      <div className="event-modal-content">
        <button className="event-modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>{event.title}</h2>
        <div className="event-modal-location">{event.eventLocation}</div>
        <div className="event-modal-description cookieconsent__details">
          <p>{parse(event.materialDescription)}</p>
        </div>
      </div>
    </div>
  );
};

EventModal.propTypes = {
  event: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

