import { graphql, Link } from 'gatsby';
import { arrayOf, shape, string, array } from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { pictureFluidType } from '../../../types/picture';
import { Content } from '../../common/content';
import { FluidPicture } from '../../common/picture';
import { getLinkProps } from '../teaser/utils';
import './events.scss';
import { EventModal } from '../../common/EventModal/EventModal'; // Correct relative import path

import dateformat from 'dateformat';

const bem = new BEMHelper({
  name: 'content-events',
});

const modal = new BEMHelper({
  name: 'content-event_modal',
});

export const Events = ({
  id,
  eventsEntry,
}) => {
  const { t } = useTranslation();
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  
  // Handler to trigger the modal with the specific event entry
  const handleReadBioClick = (entry) => {
    setSelectedEvent(entry); // Set the event to be shown in the modal
  };

  // Close modal
  const handleCloseModal = () => {
    setSelectedEvent(null); // Reset selected event to close the modal
  };
  
  

  // Adding necessary properties to the event entries
  for (let i = 0; i < eventsEntry.length; i++) {
    eventsEntry[i]['element'] = [];
    eventsEntry[i]['element']['__typename'] = eventsEntry[i]['__typename'];
    eventsEntry[i]['element']['slug'] = eventsEntry[i]['slug'];
    if (typeof(eventsEntry[i]['newsImage']) !== 'undefined') {
      eventsEntry[i]['image'] = eventsEntry[i]['newsImage'][0];
    }
  }

  return (
    <>
      {eventsEntry.map((entry) => (
        <Content key={entry.slug} id={id} {...bem()} useOwnSpacing>

          {entry.image && 
            <FluidPicture
              {...entry.image}
              maxWidth={100}
              widths={[100]}
              role="presentation"
              {...bem('image')}
            />
          }
          <div {...bem('textcontainer')}>
            <h2 {...bem('headline')}>
              <div>{entry.title}</div>
            </h2>
            <div {...bem('date')}>
              { dateformat(entry.eventStartDate, 'ddd, mmm dd, yyyy') } - { dateformat(entry.eventEndDate, 'ddd, mmm dd, yyyy') } in { entry.eventLocation }
            </div>
            <button
              {...bem('button')}
              type="button"
              class="content-team__button"
              title={t('Click here to read the biography')}
              onClick={() => handleReadBioClick(entry)} // Trigger the modal
            >
              {t('Read more')}
            </button>
          </div>

        </Content>
      ))}
      
      {/* Conditionally render the modal if there's a selected event */}
      {selectedEvent && <EventModal event={selectedEvent} onClose={handleCloseModal} />}
    </>
  );
};

Events.propTypes = {
  id: string.isRequired,
  eventsEntry: array.isRequired,
};

export const fragment = graphql`
  fragment EventsContentFragment on CraftGQL_pageContents_events_BlockType {
    id
    eventsEntry {
      ...on CraftGQL_events_events_Entry {
        __typename
        title
        slug: pageSlug
        publishedDate
        pageExcerpt
        eventStartDate
        eventLocation
        eventEndDate
        materialDescription
        newsImage {
          ...Picture
          fluid_0: url(width: 180, mode: "crop", position: "") # 0.25
        }
      }
    }
  }
`;

